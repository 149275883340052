import { Injectable } from '@angular/core';
import { IAppConfig } from './app-config.model';
import { ICertificateConfig } from './certificate-config.model';

@Injectable({ providedIn: 'root' })
export class AppConfig<T extends IAppConfig = IAppConfig> {

    public static settings: IAppConfig;
    public static certificateSetting: ICertificateConfig;
    constructor() { }

    static load(): Promise<any> {
        return Promise.all([
            fetch('/assets/config/config.json') // URL to your remote config
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Not Found');
                }),
            fetch('/assets/config/certificate-lang.json') // URL to your remote config
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Not Found');
                })
        ]).then(values => {
            AppConfig.settings = values[0];
            AppConfig.certificateSetting = values[1];
        })
    }

    getConfig(): T {
        return AppConfig.settings as T;
    }
}

