import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppConfig } from './config/app-config';

async function initialize() {
  if (AppConfig.settings.production) {
    enableProdMode();
  }

  const appModuleContainer = await import('./app/app.module');
  if(AppConfig.settings.GOOGLE_TAG_MANAGER_KEY) {
    const dataLayerScript = document.createElement('script');
    dataLayerScript.innerHTML = `window.dataLayer = window.dataLayer || [];`;
    document.head.appendChild(dataLayerScript);
  
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${AppConfig.settings.GOOGLE_TAG_MANAGER_KEY}');`;
    document.head.appendChild(script);
  }
  platformBrowserDynamic()
    .bootstrapModule(appModuleContainer.AppModule)
    .catch((err) => console.error(err));
}

AppConfig.load()
  .catch((err) => console.log('error', err))
  .finally(() => {
    initialize();
  })

